/* eslint-disable complexity */
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import withMemo from '../../decorators/withMemo'
import Image from '../Image'
import ProgressNav from '../ProgressNav'

import useStyles from './styles'


const TRANSITION_DURATION = 300

const LookCarousel = (props) => {
  const classes = useStyles({ ...props, TRANSITION_DURATION })
  const { className, looks, lookIndex, prevText, nextText } = props
  const [currentLookIndex, setCurrentIndex] = useState(lookIndex)
  const [animate, setAnimate] = useState(false)

  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array.from($animatedWrapper.current.children).filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 0, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [classes.image])

  const sideLooks = useMemo(() => ({
    prevExtremeFar: _.nth(looks, currentLookIndex - 3),
    prevFar: _.nth(looks, currentLookIndex - 2),
    prev: _.nth(looks, currentLookIndex - 1),
    current: looks[currentLookIndex],
    next: _.nth(
      looks,
      currentLookIndex === looks.length - 1
        ? 0
        : currentLookIndex === looks.length - 2
          ? looks.length - 1
          : currentLookIndex + 1
    ),
    nextFar: _.nth(
      looks,
      currentLookIndex === looks.length - 1
        ? 1
        : currentLookIndex === looks.length - 2
          ? 0
          : currentLookIndex + 2
    ),
    nextExtremeFar: _.nth(
      looks,
      currentLookIndex === looks.length - 1
        ? 2
        : currentLookIndex === looks.length - 2
          ? 1
          : currentLookIndex + 3
    ),
  }), [currentLookIndex, looks])

  const getLookClass = useCallback(
    (look) => {
      switch (look.id) {
        case sideLooks?.prevExtremeFar?.id:
          return classes.prevExtremeFar
        case sideLooks?.prevFar?.id:
          return classes.prevFar
        case sideLooks?.prev?.id:
          return classes.prev
        case sideLooks?.current?.id:
          return classes.current
        case sideLooks?.next?.id:
          return classes.next
        case sideLooks?.nextFar?.id:
          return classes.nextFar
        case sideLooks?.nextExtremeFar?.id:
          return classes.nextExtremeFar
        default:
          return ''
      }
    },
    [sideLooks, classes]
  )

  const onNavigationClick = useCallback(
    (diff) => {
      setCurrentIndex((prev) => {
        if (prev + diff > looks.length - 1) {
          return 0
        }
        if (prev + diff < 0) {
          return looks.length - 1
        }

        return prev + diff
      })
    },
    [setCurrentIndex, looks]
  )

  const onLookClick = useCallback(
    (look) => {
      switch (look.id) {
        case sideLooks?.prevExtremeFar?.id:
        case sideLooks?.prevFar?.id:
        case sideLooks?.prev?.id:
          onNavigationClick(-1)
          break
        case sideLooks?.nextExtremeFar?.id:
        case sideLooks?.nextFar?.id:
        case sideLooks?.next?.id:
          onNavigationClick(1)
          break
        default:
      }
    }, [sideLooks, onNavigationClick]
  )

  useEffect(() => {
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
    }, TRANSITION_DURATION)
  }, [currentLookIndex])

  return (
    <div
      className={cx(className, classes.container)}
      ref={$animatedWrapper}
    >
      <div className={classes.embedContainer}>
        {!animate && (
          <iframe
            src={looks[currentLookIndex].embedUrl}
            title="look-frame"
            height="100%"
            width="100%"
            frameBorder="0"
          />
        )}
      </div>
      <div className={classes.scene}>
        {looks.map((look) => (
          <button
            type="button"
            className={cx(classes.look, getLookClass(look))}
            onClick={() => onLookClick(look)}
            key={look.id}
          >
            <Image
              img={look.fluid}
              className={classes.image}
            />
          </button>
        ))}
      </div>
      <div className={classes.currentLook}>
        {looks[currentLookIndex].title}
      </div>
      <div className={classes.controls}>
        <ProgressNav
          buttonNextProps={{ onClick: () => onNavigationClick(1) }}
          buttonPrevProps={{ onClick: () => onNavigationClick(-1) }}
          textPrev={prevText}
          textNext={nextText}
          currentStep={currentLookIndex}
          totalSteps={looks.length}
          hideProgressOnMobile
        />
      </div>
    </div>
  )
}

LookCarousel.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  looks: PropTypes.any,
  lookIndex: PropTypes.number,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
}

LookCarousel.defaultProps = {
  className: null,
  looks: PropTypes.any,
  lookIndex: 0,
  prevText: '',
  nextText: '',
}

export default withMemo()(LookCarousel)
