import { createUseStyles } from 'react-jss'

import { desktop, colors, textStyles, maxDesktop } from '../../theme'


export default createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
    overflow: 'hidden',
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
  },
  embedContainer: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    height: '70vh',
    position: 'absolute',
    zIndex: 10,
    ...desktop({
      width: '43.2rem',
      height: '57.7rem',
    }),
    ...maxDesktop({
      width: '48rem',
      height: '64.2rem',
    }),
  },
  scene: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  look: {
    display: 'flex',
    width: '25rem',
    position: 'absolute',
    background: 'none',
    transition: ({ TRANSITION_DURATION }) => `transform ${TRANSITION_DURATION}ms ease-in-out, opacity ${TRANSITION_DURATION}ms ease-in-out`,
    opacity: 0,
    cursor: 'pointer',
    ...desktop({
      width: '35rem',
      height: '57.7rem',
    }),
    ...maxDesktop({
      width: '40rem',
      height: '64.2rem',
    }),
  },
  prevExtremeFar: {
    opacity: 0,
    transform: 'translateX(calc(-35vw)) scale(0.7)',
    ...desktop({
      transform: 'translateX(calc(-43vw)) scale(0.5)',
    }),
  },
  prevFar: {
    opacity: 0,
    transform: 'translateX(calc(-35vw)) scale(0.7)',
    ...desktop({
      transform: 'translateX(calc(-43vw)) scale(0.5)',
      opacity: 0.3,
    }),
  },
  prev: {
    opacity: 0.75,
    transform: 'translateX(calc(-35vw)) scale(0.7)',
    zIndex: 10,
    ...desktop({
      opacity: 0.9,
      transform: 'translateX(calc(-30vw)) scale(0.75)',
    }),
  },
  current: {
    opacity: 0,
    transform: 'translateX(0) scale(1)',
  },
  next: {
    opacity: 0.75,
    transform: 'translateX(calc(35vw)) scale(0.7)',
    zIndex: 10,
    ...desktop({
      opacity: 0.9,
      transform: 'translateX(calc(30vw)) scale(0.75)',
    }),
  },
  nextFar: {
    opacity: 0,
    transform: 'translateX(calc(35vw)) scale(0.7)',
    ...desktop({
      transform: 'translateX(calc(43vw)) scale(0.5)',
      opacity: 0.3,
    }),
  },
  nextExtremeFar: {
    opacity: 0,
    transform: 'translateX(calc(35vw)) scale(0.7)',
    ...desktop({
      transform: 'translateX(calc(43vw)) scale(0.5)',
    }),
  },
  image: {
    width: '100%',
    height: '100%',
  },
  currentLook: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: colors.pureWhite,
    textTransform: 'uppercase',
    ...textStyles.carouselCurrentLookTextMobile,
    bottom: '2rem',
    ...desktop({
      ...textStyles.carouselCurrentLookText,
      bottom: 'auto',
      marginTop: '63rem',
    }),
    ...maxDesktop({
      marginTop: '70rem',
    }),
  },
  controls: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0 1.5rem',
    zIndex: 100,
    bottom: '1.5rem',
    ...desktop({
      bottom: '3rem',
    }),
  },
})
