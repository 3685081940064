import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import withMemo from '../../decorators/withMemo'
import LookCarousel from '../../components/LookCarousel'

import useStyles from './styles'


const GridTemplate = (props) => {
  const classes = useStyles(props)
  const { className, looks, lookIndex, nextText, prevText } = props

  return (
    <main className={cx(className, classes.container)}>
      <div className={classes.wrapper}>
        {looks && (
        <LookCarousel
          looks={looks}
          lookIndex={lookIndex}
          nextText={nextText}
          prevText={prevText}
        />
        )}
      </div>
    </main>
  )
}

export const GridTemplatePropTypes = {
  className: PropTypes.string,
  looks: PropTypes.any,
  lookIndex: PropTypes.number,
  nextText: PropTypes.string,
  prevText: PropTypes.string,
}

GridTemplate.propTypes = GridTemplatePropTypes

GridTemplate.defaultProps = {
  className: null,
  looks: null,
  lookIndex: 0,
  nextText: '',
  prevText: '',
}

export default withMemo()(GridTemplate)
